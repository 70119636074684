const FacebookIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0H3.75001C1.67926 0 0 1.67925 0 3.75V14.25C0 16.3208 1.67926 18 3.75001 18H14.25C16.3216 18 18.0001 16.3208 18.0001 14.25V3.75C18.0001 1.67925 16.3216 0 14.25 0ZM12 5.25H10.557C10.0958 5.25 9.75003 5.439 9.75003 5.91676V6.75001H12L11.8215 9.00001H9.75003V15H7.50003V9.00001H6.00002V6.75001H7.50003V5.30775C7.50003 3.79125 8.29803 3 10.0958 3H12V5.25Z"
      fill="currentColor"
    />
  </svg>
);

const LinkedInIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0H3.75001C1.67926 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67926 18 3.75001 18H14.25C16.3216 18 18.0001 16.3207 18.0001 14.25V3.75C18.0001 1.67925 16.3216 0 14.25 0ZM6.00002 14.25H3.75001V6H6.00002V14.25ZM4.87502 5.049C4.15051 5.049 3.56251 4.4565 3.56251 3.726C3.56251 2.9955 4.15051 2.403 4.87502 2.403C5.59952 2.403 6.18752 2.9955 6.18752 3.726C6.18752 4.4565 5.60027 5.049 4.87502 5.049ZM15.0001 14.25H12.75V10.047C12.75 7.521 9.75003 7.71225 9.75003 10.047V14.25H7.50003V6H9.75003V7.32375C10.797 5.38425 15.0001 5.241 15.0001 9.18075V14.25Z"
      fill="currentColor"
    />
  </svg>
);

const VimeoIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 448 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M403.2 32H44.8C20.1 32 0 52.1 0 76.8v358.4C0 459.9 20.1 480 44.8 480h358.4c24.7 0 44.8-20.1 44.8-44.8V76.8c0-24.7-20.1-44.8-44.8-44.8zM377 180.8c-1.4 31.5-23.4 74.7-66 129.4-44 57.2-81.3 85.8-111.7 85.8-18.9 0-34.8-17.4-47.9-52.3-25.5-93.3-36.4-148-57.4-148-2.4 0-10.9 5.1-25.4 15.2l-15.2-19.6c37.3-32.8 72.9-69.2 95.2-71.2 25.2-2.4 40.7 14.8 46.5 51.7 20.7 131.2 29.9 151 67.6 91.6 13.5-21.4 20.8-37.7 21.8-48.9 3.5-33.2-25.9-30.9-45.8-22.4 15.9-52.1 46.3-77.4 91.2-76 33.3.9 49 22.5 47.1 64.7z"></path>
  </svg>
);
export { LinkedInIcon, FacebookIcon, VimeoIcon };
