const ArrowIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-1.49609 10.873H22.43M14.9532 2.19727L23.6761 10.873L14.9532 19.5487"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
};
export default ArrowIcon;
