import { FacebookIcon, LinkedInIcon, VimeoIcon } from "../icons/footer.icons";
import ArrowIcon from "../icons/arrow.icon";
import ArrowUpIcon from "../icons/arrow-up.icon";
import RenLink from "../shared/RenLink";
import Link from "next/link";

interface ContactInfo {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  postalPlace: string;
  email: string;
  phoneNumber: string;
  organizationNumber: string;
  newsletterText: string;
  newsletterButtonText: string;
  newsletterURL: string;
  socialMediaText: string;
  facebookURL: string;
  linkedInURL: string;
  vimeoURL: string;
}

interface Settings {
  contactInfo: ContactInfo;
}

interface Props {
  contact?: string;
  settings?: Settings;
}

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const scrollTopStyle = {
  margin: "0 auto",
  display: "block",
  padding: "1em",
  fontSize: "inherit",
};
const scrollTopArrow = {
  verticalAlign: "middle",
  marginLeft: "10px",
};

const Footer: React.FC<Props> = ({
  contact,
  settings: {
    contactInfo: {
      addressLine1 = "REN AS",
      addressLine2 = "Fantoftvegen 2",
      postalCode = "5072",
      postalPlace = "BERGEN",
      email = "post@ren.no",
      phoneNumber = "47 47 99 00",
      organizationNumber = "979 986 211",
      newsletterText = "Få med deg aktuelt bransjenytt",
      newsletterButtonText = "Meld deg på RENs nyhetsbrev",
      newsletterURL = "https://ren.us8.list-manage.com/subscribe?u=eb29ca84571a0802cf06e2913&id=ec536d3fe8",
      socialMediaText = "Følg oss på sosiale medier",
      facebookURL = "http://www.facebook.com/rensiden",
      linkedInURL = "http://www.linkedin.com/company/ren-as/",
      vimeoURL = "https://vimeo.com/renvideo",
    } = {},
  } = {},
}) => (
  <>
    <div role="navigation" className="c-scroll-to-top">
      <button
        aria-label="Gå til toppen av siden"
        onClick={scrollTop}
        style={scrollTopStyle}
        className="c-link"
      >
        Tilbake til toppen
        <span style={scrollTopArrow}>
          <ArrowUpIcon />
        </span>
      </button>
    </div>
    <footer className="c-footer">
      <div className="o-grid o-wrapper u-padding-top--larger u-padding-bottom--large">
        <div className="o-grid__item o-grid__item--third u-padding-bottom--large">
          <h3 className="u-text--lightGreen c-footer__header">Kontakt</h3>
          <p>
            {addressLine1}
            <br />
            {addressLine2}
            <br />
            {postalCode} {postalPlace}
          </p>
          <p>
            E-post: <a href={`mailto:${email}`}>{email}</a>
            <br />
            Telefon:{" "}
            <a href={`tel:+47${phoneNumber.replace(/ /g, "-")}`}>
              {phoneNumber}
            </a>
          </p>
          <p>Org.nr: {organizationNumber}</p>
          <p>
            <RenLink href={contact} classes="">
              Kontakt oss
            </RenLink>
          </p>
          <p>
            <Link href="/faktura">Faktura</Link>
          </p>
          <p>
            <Link href="/miljoprofil">Miljøprofil</Link>
          </p>
          <p>
            <Link href="/personvern">Personvern</Link>
          </p>
          <p>
            <Link href="/passord-og-sikkerhet">Hjelp med pålogging</Link>
          </p>
          <p>
            <Link href="/brukerpanel">Brukerpanel</Link>
          </p>
          <p>
            <Link href="/jobb-hos-oss">Jobb hos oss</Link>
          </p>
        </div>
        <div className="o-grid__item o-grid__item--third u-padding-bottom--large">
          <h3 className="u-text--lightGreen c-footer__header">Nyhetsbrev</h3>
          <div>
            <p>{newsletterText}</p>
            <a href={newsletterURL} className="c-footer__newsletter">
              {newsletterButtonText}
              <span className="c-footer__arrow">
                <ArrowIcon />
              </span>
            </a>
          </div>
          <br />
          <p>{socialMediaText}</p>
          <div className="c-footer__some-link">
            <RenLink href={facebookURL} classes="">
              <span className="c-footer__some-icon">
                <FacebookIcon />
              </span>
              Facebook
            </RenLink>
          </div>
          <div className="c-footer__some-link">
            <RenLink href={linkedInURL} classes="">
              <span className="c-footer__some-icon">
                <LinkedInIcon />
              </span>
              LinkedIn
            </RenLink>
          </div>
          <div className="c-footer__some-link">
            <RenLink href={vimeoURL} classes="">
              <span className="c-footer__some-icon">
                <VimeoIcon />
              </span>
              Vimeo
            </RenLink>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
