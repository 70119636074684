const ArrowUpIcon = () => {
  return (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.67573 25.1722L9.67573 4.2461M1 11.7229L9.67573 3L18.3514 11.7229"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};
export default ArrowUpIcon;
