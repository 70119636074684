import Link from "next/link";
import {
  resolveSanityUrl,
  resolveSanityUrlFilepath,
} from "../../helpers/resolveSanityUrl";

interface RenLink {
  href: any | string;
  children: any;
  rest?: any;
  classes?: string;
  onClick?: any;
}

/**
 * Wrap NextJS's default Link component so that we can
 * auto-calculate how a given document should be linked
 * to. Any parameters this component doesn't use will
 * be passed on to NextJS's component.
 */
const RenLink: React.FC<RenLink> = ({
  href = "",
  children,
  classes,
  ...rest
}) => {
  const linkClasses = "ren-link " + (classes || "");
  if (typeof href === "string" && href.startsWith("http")) {
    /**
     * Assume any urls starting with http or https to be external
     * they need to get regular url.
     * See: https://github.com/vercel/next.js/blob/master/errors/invalid-href-passed.md
     */
    return (
      <a href={href} className={linkClasses} {...rest}>
        {children}
      </a>
    );
  } else if (typeof href === "string") {
    // Handle relative urls.
    return (
      <Link href={href} {...rest} className={linkClasses} {...rest}>
        {children}
      </Link>
    );
  }

  // Guard Clause, Studio should validate the presence
  if (!href) {
    return <span className={"no-href"}>{children}</span>;
  }
  const { _id, _type } = href;

  if (_type === "link") {
    const { internalPage, url } = href;
    return (
      <Link
        href={resolveSanityUrl(internalPage) || url || "no-url-defined"}
        className={linkClasses}
        {...rest}
      >
        {children}
      </Link>
    );
  } else if (_id || _type) {
    // If _id or _type is defined, try to create Sanity-based Link.
    const resolvedFilePath = resolveSanityUrlFilepath(href);
    const resolvedUrl = resolveSanityUrl(href);
    if (resolvedFilePath && resolvedUrl) {
      return (
        <Link href={resolvedUrl} className={linkClasses} {...rest}>
          {children}
        </Link>
      );
    }
    //_id or _type is defined, but does not exist in resolve helpers, meaning entry has no page. Pass dead link.
    else {
      return (
        <a className={classes} {...rest}>
          {children}
        </a>
      );
    }
  }
  // If no match, fallback to just passing the href to next/link.
  return (
    <Link href={href} className={linkClasses} {...rest}>
      {children}
    </Link>
  );
};

export default RenLink;
